.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.5 !important;
}

.swiper-button-next {
  left: auto;
  right: 0;
}

.swiper-button-prev {
  left: auto;
  right: left;
}
.mySwiper .swiper-slide-thumb-active {
  opacity: 1 !important;
  border: 1px solid #6a6a6a !important;
}

.mySwiper2 {
  height: 100% !important;
  width: 100% !important;
}

.swiper-slide img {
  display: block !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: contain !important;
}

.mySwiper .swiper-slide {
  padding: 1rem;
  border: 1px solid #f1f1f1;
  padding: 1rem;
  opacity: 65%;
  border-radius: 0.5rem;
}

.mySwiper2 .swiper-slide {
  padding: 0rem 0rem;
}

.mySwiper .swiper-slide img {
  display: block !important;
  width: 82px !important;
  margin: 0 auto;
  height: 82px !important;
  object-fit: contain !important;
}

@media (min-width: 1280px) {
  .mySwiper2 {
    height: 70% !important;
    width: 100% !important;
  }

  .mySwiper2 .swiper-slide {
    padding: 1rem 1rem;
  }
}
