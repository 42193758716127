.navigation-link {
  padding: 0.5rem 1rem;
  border: none;
  margin-left: 1rem;
  border-radius: 24px;
  transition: border 250ms ease-in;
  border: 1px solid rgb(232, 232, 232, 0);
}

.navigation-link:hover {
  border-color: #e8e8e8;
}

.navigation-link-active {
  border-color: #e8e8e8;
}

.colorful-text {
  /* background: rgb(65, 64, 160);
  background: linear-gradient(
    121deg,
    rgba(65, 64, 160, 1) 0%,
    rgba(53, 147, 208, 1) 17%,
    rgba(238, 37, 77, 1) 40%,
    rgba(238, 223, 185, 1) 52%,
    rgba(135, 45, 214, 1) 100%
  ); */
  color: transparent;
  background-repeat: no-repeat;
  color: white;
  background-clip: text;
  background-size: 100%;
  background-position: 50% 50%;
}

.overlay {
  z-index: 1;
  height: 100%;
  width: 100%;
  background: linear-gradient(174deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.1) 51%, rgba(0, 0, 0, 0.8) 76%),
    linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 54%, rgba(0, 0, 0, 0.9) 70%);
}

.overlay-rtl {
  z-index: 1;
  height: 100%;
  width: 100%;
  background: linear-gradient(174deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.1) 51%, rgba(0, 0, 0, 0.8) 76%),
    linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 54%, rgba(0, 0, 0, 0.9) 70%);
}

.product-overlay {
  z-index: 1;
  height: 100%;
  width: 100%;
  background: linear-gradient(174deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.1) 21%, rgba(0, 0, 0, 1) 60%),
    linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 34%, rgba(0, 0, 0, 0.1) 100%);
}

.product-overlay-rtl {
  z-index: 1;
  height: 100%;
  width: 100%;
  background: linear-gradient(174deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.1) 21%, rgba(0, 0, 0, 1) 60%),
    linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 34%, rgba(0, 0, 0, 0.1) 100%);
}

@media (min-width: 1024px) {
  .product-overlay {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0.865983893557423) 65%,
      rgba(0, 0, 0, 1) 72%
    );
  }

  .product-overlay-rtl {
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0.865983893557423) 65%,
      rgba(0, 0, 0, 1) 72%
    );
  }
}

.cover-image {
  position: absolute;
  right: -40%;
  top: 0;
}
.App {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  min-width: 0;
  height: 100%;
}
/* 
@media (min-width: 1280px) {
  .container {
    max-width: 1280px !important;
  }
} */

.product-image-wrapper {
  max-width: 60%;
  overflow: hidden;
}

.product-image-wrapper img {
}

[dir='rtl'] .icon {
  transform: scaleX(-1);
}

@font-face {
  font-family: 'Rabar_015';
  src: url(../assets/fonts/Rabar_021.eot); /* IE9 Compat Modes */
  src: url(../assets/fonts/Rabar_021.eot) format('embedded-opentype'),
    /* IE6-IE8 */ url(../assets/fonts/Rabar_021.ttf) format('truetype'),
    /* Safari, Android, iOS */ url(../assets/fonts/Rabar_021.woff) format('woff'); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

.lang-ku {
  font-family: 'Rabar_015', Arial;
}
.lang-ar {
  font-family: 'Rabar_015', serif;
}

.pagination {
  @apply rounded-lg my-8;
  grid-column-start: 3;
  grid-column-end: 9;
}
.pagination ul {
  display: inline-block;
  padding-left: 1rem;
  padding-right: 1rem;
}

.pagination li {
  display: inline-block;
  margin-top: 1rem;
  }

.pagination li a {
  @apply bg-white text-main rounded-lg mx-2 p-4 inline-block;
}

.pagination li:hover {
  text-decoration: underline;
}

.active {
  color: black;
  font-weight: 600;
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.3);
}
